<template>
  <div class="col main_teams_wrapper">
    <!-- Main div for permissions -->
    <div>
      <!-- main div for adding loading in every functionality  -->
      <div v-loading="loadingValue">
        <!-- Start Team Progress Timeline [ Large Screen] -->
        <card class="card_progress_container">
          <div class="main_timeline_wrapper ">
            <div class="steps_container">
              <!-- first_step i removed this class for active -->
              <div class="one_step_wrapper ">
                <span class="step">
                  <i class="fa fa-check"></i>
                </span>
              </div>
              <div class="one_step_wrapper">
                <span class="step">
                  <i class="fa fa-check"></i>
                </span>
              </div>
              <div class="one_step_wrapper">
                <span class="step">
                  <i class="fa fa-check"></i>
                </span>
              </div>
              <div class="one_step_wrapper">
                <span class="step">
                  <i class="fa fa-check"></i>
                </span>
              </div>
            </div>
            <div class="all_steps_cards_container">
              <div class="every_card_step">
                <router-link to="/introduce-yourself" class="router_cards">
                  <h4>{{ localization("Card One Title") }}</h4>
                  <div
                    :class="{ 'text-right': isRtl() }"
                    v-if="team_cards_content[0]"
                    v-html="team_cards_content[0].body"
                  ></div>
                </router-link>
              </div>
              <div class="every_card_step">
                <router-link to="/building-team" class="router_cards">
                  <h4>{{ localization("Card Two Title") }}</h4>
                  <div
                    :class="{ 'text-right': isRtl() }"
                    v-if="team_cards_content[1]"
                    v-html="team_cards_content[1].body"
                  ></div>
                </router-link>
              </div>
              <div class="every_card_step">
                <router-link to="/working-together" class="router_cards">
                  <h4>
                    {{ localization("Card Three Title") }}
                  </h4>
                  <div
                    :class="{ 'text-right': isRtl() }"
                    v-if="team_cards_content[2]"
                    v-html="team_cards_content[2].body"
                  ></div>
                </router-link>
              </div>
              <div class="every_card_step">
                <router-link to="/tackling-project" class="router_cards">
                  <h4>{{ localization("Card Four Title") }}</h4>
                  <div
                    :class="{ 'text-right': isRtl() }"
                    v-if="team_cards_content[3]"
                    v-html="team_cards_content[3].body"
                  ></div>
                </router-link>
              </div>
            </div>
          </div>
        </card>
        <!-- End Team Progress Timeline [ Large Screen] -->

        <!--//// Start All Teams Card -->
        <card>
          <template #header>
            <div
              class="
                header_contact_wrapping mb-4
              "
              :class="{ 'flex-row-reverse': isRtl() }"
            >
              <h4
                class="card-title"
                :class="{ 'd-flex flex-row-reverse': isRtl() }"
              >
                <i class="el-icon-collection group-title-icon"></i>
                <span :class="{ 'mr-2': isRtl() }">{{
                  localization("Teams")
                }}</span>
              </h4>
              <div class="header_buttons_wrapper d-none">
                <l-button
                  @click="open_add_group_dialog(1, {})"
                  type="info"
                  wide
                  class="d-block add_contact_buttons"
                >
                  {{ localization("Add New Team") }}
                </l-button>
              </div>
            </div>
          </template>
          <!--///// Start Collapse  -->
          <el-collapse accordion>
            <el-collapse-item
              v-for="team in allTeamsList"
              :key="team.id"
              :title="team.name"
              :name="team.id"
              :disabled="team.users_count < 3"
            >
              <!-- Start Header  -->
              <template slot="title">
                <div
                  class="wrapper_collapse_for_header"
                  :class="{ 'flex-row-reverse': isRtl() }"
                >
                  <div class="head_part_one">
                    <span class="d-block user-name text-capitalize">
                      {{ team.name }}
                    </span>
                  </div>
                  <div class="head_part_two">
                    <div
                      class="main_actions"
                      :class="{ 'flex-row-reverse': isRtl() }"
                    >
                      <span
                        class="actions-icons team_count"
                        v-tooltip.top-center="
                          `${localization('Team members count')}`
                        "
                        >{{ team.users_count }}</span
                      >
                      <i
                        @click="
                          check_count_members_and_statements(
                            team.users_count,
                            team.id
                          )
                        "
                        v-tooltip.top-center="
                          `${localization('Show team details')}`
                        "
                        class="fa fa-eye actions-icons reinvite"
                      ></i>
                      <i
                        @click="open_add_team_members_dialog(team.id)"
                        v-if="
                          auth().id == team.user_id ||
                            hasPermission('admin_team')
                        "
                        v-tooltip.top-center="
                          `${localization('Add team members')}`
                        "
                        class="fa fa-plus actions-icons add"
                        :class="{ 'd-none': team.users_count >= 5 }"
                      ></i>
                      <i
                        @click="open_add_group_dialog(2, team)"
                        v-if="
                          auth().id == team.user_id ||
                            hasPermission('admin_team')
                        "
                        v-tooltip.top-center="
                          `${localization('Edit team name')}`
                        "
                        class="fa fa-edit  actions-icons edit"
                      ></i>
                      <i
                        @click="remove_team(team.id)"
                        v-if="
                          auth().id == team.user_id ||
                            hasPermission('admin_team')
                        "
                        v-tooltip.top-center="`${localization('Delete team')}`"
                        class="fa fa-times actions-icons delete"
                      ></i>
                      <i
                        @click="Leave_team(team.id, auth().id)"
                        v-tooltip.top-center="`${localization('Leave team')}`"
                        class="fa fa-level-up actions-icons delete"
                      ></i>
                    </div>
                  </div>
                </div>
              </template>
              <!-- End Header  -->

              <!-- Start Team Members  -->
              <div class="all_details_for_team_members">
                <div
                  class="every_team_members"
                  :class="{ 'flex-row-reverse': isRtl() }"
                  v-for="member in team.members"
                  :key="member.id"
                >
                  <div class="part_a_member">
                    {{ member.first_name }} {{ member.last_name }}
                  </div>
                  <div class="part_b_member">
                    <!-- Start LCI Scores  -->
                    <div
                      class="
                    flex-grow-1 flex-wrap
                    d-flex
                    justify-content-around
                    align-items-center
                    text-capitalize
                    main-scores-container-sm main-scores-container
                  "
                    >
                      <div
                        class="d-flex align-items-center every-score"
                        :class="{ 'flex-row-reverse': isRtl() }"
                      >
                        <span class="d-block score-word">{{
                          localization("Sequence shortcut")
                        }}</span>
                        <span class="label sequence d-block ">
                          {{ member.sequence != null ? member.sequence : 0 }}
                        </span>
                      </div>
                      <div
                        class="d-flex align-items-center every-score"
                        :class="{ 'flex-row-reverse': isRtl() }"
                      >
                        <span class="d-block score-word">{{
                          localization("precision shortcut")
                        }}</span>
                        <span class="label precision d-block ">
                          {{ member.precision != null ? member.precision : 0 }}
                        </span>
                      </div>
                      <div
                        class="d-flex align-items-center every-score"
                        :class="{ 'flex-row-reverse': isRtl() }"
                      >
                        <span class="d-block score-word">{{
                          localization("Technical Reasoning shortcut")
                        }}</span>
                        <span class="label technical_reasoning d-block ">
                          {{
                            member.technical_reasoning != null
                              ? member.technical_reasoning
                              : 0
                          }}
                        </span>
                      </div>
                      <div
                        class="d-flex align-items-center every-score"
                        :class="{ 'flex-row-reverse': isRtl() }"
                      >
                        <span class="d-block score-word">{{
                          localization("Confluence shortcut")
                        }}</span>
                        <span class="label confluence d-block ">
                          {{
                            member.confluence != null ? member.confluence : 0
                          }}
                        </span>
                      </div>
                    </div>
                    <!-- Start LCI Scores  -->
                  </div>
                  <div class="part_c_member">
                    <div class="main_actions">
                      <i
                        @click="delete_member_from_team(team.id, member.id)"
                        v-if="
                          auth().id == team.user_id ||
                            hasPermission('admin_team')
                        "
                        v-tooltip.top-center="
                          `${localization('Delete Member')}`
                        "
                        class="fa fa-times actions-icons delete"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Invited Members  -->
              <div
                class="main_invitation_members_wrapper"
                v-for="(member, index) in team.invited_member"
                :key="index"
              >
                <div
                  class="every_invitation"
                  :class="{ 'flex-row-reverse': isRtl() }"
                >
                  <div class="part_i" :class="{ 'flex-row-reverse': isRtl() }">
                    <i class="fa fa-envelope email_icon"></i>
                    <span class="email_address">{{ member }}</span>
                  </div>
                  <div class="part_ii">
                    <i
                      v-if="
                        auth().id == team.user_id || hasPermission('admin_team')
                      "
                      @click="delete_invited_member_team(team.id, member)"
                      v-tooltip.top-center="
                        `${localization('Cancel invitation')}`
                      "
                      class="fa fa-times actions-icons delete"
                    ></i>
                  </div>
                </div>
              </div>
              <!-- End Team Members  -->
            </el-collapse-item>
          </el-collapse>
          <!--//// End Collapse  -->
        </card>
        <!--//// End All Teams  Card -->
      </div>

      <!-- Start Add Or Edit New Team Dialog //todo: localization -->
      <el-dialog
        :title="
          type == 1
            ? `${localization('Add A Team')}`
            : `${localization('Edit Team')}`
        "
        :visible.sync="add_group_dialog"
        width="50%"
        top="4vh"
      >
        <el-form :model="add_group_name_data" :rules="rules" ref="addTeam">
          <el-form-item :label="`${localization('Name')}`" prop="name">
            <el-input v-model="add_group_name_data.name"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer dialog_footer">
          <el-button
            @click="close_add_group_dialog"
            class="text-center d-block"
            >{{ localization("Cancel") }}</el-button
          >
          <el-button
            type="submit"
            @click="save_team"
            class="text-center d-block text-capitalize"
            >{{ localization("Save") }}</el-button
          >
        </div>
      </el-dialog>
      <!--End Add Or Edit New Team Dialog -->

      <!-- Start Invitation Member By Email Dialog -->
      <el-dialog
        :title="`${localization('Reinvite Member')}`"
        :visible.sync="show_invite_member_dialog"
        top="4vh"
      >
        <el-form
          :model="for_reinvite_new_member"
          :rules="rules"
          ref="for_reinvite_new_member"
        >
          <el-form-item
            :label="`${localization('Email')}`"
            prop="email"
            :rules="[
              {
                required: true,
                message: 'Please input email address',
                trigger: 'blur'
              },
              {
                type: 'email',
                message: 'Please input correct email address',
                trigger: ['blur', 'change']
              }
            ]"
          >
            <el-input
              v-model="for_reinvite_new_member.email"
              autocomplete="off"
              style="width: 100%"
              :placeholder="`${localization('Type Email')}`"
              type="email"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer main_dialog_footer">
          <el-button
            @click="close_invite_member_dialog"
            class="for_c_buttons text-center text-capitalize"
            >{{ localization("Cancel") }}</el-button
          >
          <el-button
            type="primary"
            class="text-center for_c_btns text-capitalize"
            @click="invite_new_member"
            >{{ localization("Send") }}</el-button
          >
        </div>
      </el-dialog>
      <!-- End Invitation Member By Email Dialog -->

      <!-- Start Add Team Members //todo: localization -->
      <el-dialog
        :title="`${localization('Add Members')}`"
        :visible.sync="show_add_members_dialog"
        width="50%"
        top="4vh"
        :close-on-click-modal="false"
      >
        <el-form :rules="rules" ref="teamRef">
          <el-form-item :label="`${localization('Members')}`">
            <el-select
              ref="users"
              :placeholder="`${localization('Select Members')}`"
              no-data-text="Type Name"
              style="width: 100%"
              v-model="selected_team_members"
              filterable
              multiple
              remote
              reserve-keyword
              :remote-method="search"
              :loading="loadingValue"
            >
              <el-option
                v-for="user in contactList"
                :key="user.id"
                :label="user.first_name + ' ' + user.last_name"
                :value="user.id"
                :disabled="selected_team_members.length == 5"
              >
                <span style="color: #8492a6; font-size: 13px">
                  <i class="el-icon-user-solid"></i>
                  {{ user.first_name + " " + user.last_name }}
                </span>
              </el-option>
            </el-select>
          </el-form-item>
          <div>
            {{ localization("Reinvite members by email") }}
          </div>
          <el-form-item
            :label="`${localization('Email')}`"
            style="width: 100%"
            prop="questions_options"
          >
            <el-select
              v-model="emails"
              no-data-text="Type Email"
              multiple
              filterable
              allow-create
              default-first-option
              class=""
              style="width: 100%"
              :placeholder="`${localization('Type Email')}`"
            >
            </el-select>
          </el-form-item>
          <el-form-item label="Institution Users" prop="">
            <el-select
              v-model="selected_institutions_users"
              :placeholder="`${localization('Please select user')}`"
              style="width: 100%"
              multiple
              filterable
            >
              <el-option
                v-for="item in institutionUsersList"
                :key="item.id"
                :label="item.first_name + ' ' + item.last_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div
          slot="footer"
          class="
            dialog-footer
            dialog_footer
          "
        >
          <el-button
            @click="close_add_team_members_dialog"
            class="text-center d-block"
            >{{ localization("Cancel") }}</el-button
          >

          <el-button
            @click="add_members_to_team"
            class="
              text-center
              d-block
              text-capitalize
            "
            >{{ localization("Save") }}</el-button
          >
        </div>
      </el-dialog>
      <!-- End Add Team Members  -->
    </div>
  </div>
</template>

<script>
import { Card } from "src/components/index";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
// import Card from "../../components/Cards/Card.vue";
export default {
  name: "MyTeams",
  data() {
    return {
      userStatementsData: [],
      emails: [],
      add_group_dialog: false,
      type: null,
      add_group_name_data: {
        name: null
        // description: null
      },
      rules: {
        name: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ]
      },
      teams: [
        {
          id: 1,
          name: "team one"
        },
        {
          id: 2,
          name: "team two"
        },
        {
          id: 3,
          name: "team three"
        }
      ],
      show_invite_member_dialog: false,
      for_reinvite_new_member: {
        email: null
      },
      show_add_members_dialog: false,
      selected_team_members: [],
      getEveryTeamId: null,
      selected_institutions_users: []

      // Statements
    };
  },
  mounted() {
    this.get_all_teams();
    this.Refresh();
    this.$store.dispatch("user/user", this.auth().id).then(() => {
      this.userStatementsData = this.$store.state.user.user.my_team_statements;
    });
    this.$store.dispatch("groups/institutionUsersGroup");
  },
  components: {
    Card
  },
  computed: {
    ...mapGetters({
      loadingValue: ["user/usersLoad"],
      contactList: ["contacts/contactList"],
      allTeamsList: ["user/get_teams"],
      userStatements: ["attributes/get_user_statements"],
      team_cards_content: ["user/get_team_cards_content"],
      team_pages_content: ["user/get_team_pages_content"],
      institutionUsersList: ["groups/getInstUsers"]
    }),
    every_team_statements_type() {
      return this.$store.getters["attributes/get_every_team_statements_type"];
    }
  },
  methods: {
    Refresh(query) {
      this.$store.dispatch("attributes/GetTeamStatements", { query: query });
      this.$store.dispatch("attributes/getUserStatements", { query: query });
    },
    // Check If The Team Less Than 3 Members OR User Have'nt Statements
    check_count_members_and_statements(member_count, team_id) {
      let auth_user = this.auth();
      if (member_count < 3) {
        Swal.fire({
          title: "",
          text: `${this.localization("This team is less than 3 members")}`,
          type: "warning",
          confirmButtonClass: "btn btn-success",
          buttonsStyling: false,
          icon: "warning"
        });
      } else if (
        auth_user.my_team_statements == null ||
        this.userStatementsData.length <= 0
      ) {
        Swal.fire({
          title: "",
          text: `${this.localization("Please introduce yourself")}`,
          type: "warning",
          confirmButtonClass: "btn btn-success",
          buttonsStyling: false,
          icon: "warning"
        }).then(date => {
          if (date.isConfirmed) {
            this.$router.push(`/introduce-yourself`);
          }
        });
      } else {
        this.$router.push(`/teams/${team_id}`);
      }
    },
    // Get All Teams /edit-user
    get_all_teams() {
      this.$store.dispatch("user/get_all_teams");
      this.$store.dispatch("user/get_all_teams_content");
    },
    // open add group dialog
    open_add_group_dialog(type, item) {
      this.add_group_dialog = !this.add_group_dialog;
      this.add_group_name_data = item;
      this.type = type;
    },
    // close add group dialog
    close_add_group_dialog() {
      this.get_all_teams();
      this.add_group_dialog = false;
      this.add_group_name_data = {};
      this.type = null;
    },
    // Add Or Edit Team
    save_team() {
      this.$refs["addTeam"].validate(valid => {
        if (valid) {
          if (this.type == 1) {
            this.$store
              .dispatch("user/add_team", {
                name: this.add_group_name_data.name
                // description: this.add_group_name_data.description
              })
              .then(() => {
                this.add_group_dialog = !this.add_group_dialog;
                this.add_group_name_data.name = "";
                // this.add_group_name_data.description = "";
                this.get_all_teams();
                Swal.fire({
                  title: "",
                  icon: "success",
                  text: `${this.localization("Done")}`,
                  confirmButtonText: `${this.localization("Ok")}`,
                  type: "success",
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false
                });
              });
          } else {
            this.$store
              .dispatch("user/edit_team", {
                name: this.add_group_name_data.name,
                // description: this.add_group_name_data.description,
                id: this.add_group_name_data.id
              })
              .then(() => {
                this.add_group_dialog = !this.add_group_dialog;
                this.add_group_name_data.name = "";
                this.get_all_teams();
                Swal.fire({
                  title: "",
                  icon: "success",
                  text: `${this.localization("Done")}`,
                  confirmButtonText: `${this.localization("Ok")}`,
                  type: "success",
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false
                });
              });
          }
        }
      });
    },
    // Remove Team
    remove_team(team_id) {
      Swal.fire({
        title: "",
        text: `${this.localization("Are You Sure")}`,
        type: "warning",
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success ",
        cancelButtonClass: "btn btn-danger ",
        confirmButtonText: `${this.localization("Delete")}`,
        cancelButtonText: `${this.localization("Cancel")}`,
        buttonsStyling: false
      }).then(date => {
        if (date.isConfirmed) {
          this.$store
            .dispatch("user/delete_team_from_teams", team_id)
            .then(() => {
              this.get_all_teams();
              Swal.fire({
                title: "",
                icon: "success",
                type: "success",
                text: `${this.localization("Deleted")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false
              });
            });
        }
      });
    },
    // Leave Team
    Leave_team(team_id) {
      Swal.fire({
        text: `${this.localization("Are You Sure")}`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        confirmButtonText: "Leave",
        buttonsStyling: false,
        icon: "error"
      }).then(date => {
        if (date.isConfirmed) {
          this.$store
            .dispatch("user/delete_teams", {
              teamId: team_id,
              authId: this.auth().id
            })
            .then(() => {
              this.get_all_teams();
              Swal.fire({
                title: "",
                text: `${this.localization("Done")}`,
                type: "success",
                confirmButtonClass: "btn btn-success",
                buttonsStyling: false,
                icon: "success"
              });
            });
        }
      });
    },
    // Open Invite Member By Email Dialog
    open_invite_member_dialog(id) {
      this.getEveryTeamId = id;
      this.show_invite_member_dialog = !this.show_invite_member_dialog;
    },
    // Close Invite Member By Email Dialog
    close_invite_member_dialog() {
      this.show_invite_member_dialog = false;
      this.for_reinvite_new_member.email = "";
      this.getEveryTeamId = null;
    },
    // Invite New Member To Team By Email
    invite_new_member() {
      this.$refs["for_reinvite_new_member"].validate(valid => {
        if (valid) {
          this.$store
            .dispatch("user/invite_one_to_team", {
              id: this.getEveryTeamId,
              emails: [this.for_reinvite_new_member.email]
            })
            .then(() => {
              this.getEveryTeamId = null;
              this.show_invite_member_dialog = !this.show_invite_member_dialog;
              this.for_reinvite_new_member.email = "";
              Swal.fire({
                title: "",
                icon: "success",
                text: `${this.localization("Done")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                type: "success",
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false
              });
            });
        }
      });
    },
    // Open Add Members To Team Dialog
    open_add_team_members_dialog(id) {
      this.show_add_members_dialog = !this.show_add_members_dialog;
      this.selected_team_members = [];
      this.getEveryTeamId = id;
    },
    // Close Add Members To Team Dialog
    close_add_team_members_dialog() {
      this.show_add_members_dialog = !this.show_add_members_dialog;
      this.selected_team_members = [];
      this.getEveryTeamId = null;
    },
    // Add Members To Team Dialog
    add_members_to_team() {
      let selectedMem = [
        ...this.selected_team_members,
        ...this.selected_institutions_users
      ];
      // console.log(this.getEveryTeamId);
      // console.log(this.selected_team_members);
      // console.log(this.emails);
      // console.log(this.selected_institutions_users);
      // console.log("all", selectedMem);
      this.$store
        .dispatch("user/invite_one_to_team", {
          id: this.getEveryTeamId,
          users: selectedMem,
          // users: this.selected_team_members,
          emails: this.emails
        })
        .then(() => {
          this.show_add_members_dialog = !this.show_add_members_dialog;
          this.get_all_teams();
          this.selected_team_members = [];
          this.selected_institutions_users = [];
          Swal.fire({
            title: "",
            icon: "success",
            text: `${this.localization("Done")}`,
            confirmButtonText: `${this.localization("Ok")}`,
            type: "success",
            confirmButtonClass: "btn btn-success ",
            buttonsStyling: false
          });
        });

      // });
    },
    // Delete Member From Team
    delete_member_from_team(teamId, memberId) {
      Swal.fire({
        title: "",
        text: `${this.localization("Are You Sure")}`,
        type: "warning",
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success ",
        cancelButtonClass: "btn btn-danger ",
        confirmButtonText: `${this.localization("Delete")}`,
        cancelButtonText: `${this.localization("Cancel")}`,
        buttonsStyling: false
      }).then(date => {
        if (date.isConfirmed) {
          this.$store
            .dispatch("user/delete_member_from_team", {
              team_id: teamId,
              member_id: memberId
            })
            .then(_ => {
              this.get_all_teams();
              Swal.fire({
                title: "",
                icon: "success",
                type: "success",
                text: `${this.localization("Deleted")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false
              });
            });
        }
      });
    },
    // Delete Invited From Team
    delete_invited_member_team(teamId, email) {
      Swal.fire({
        title: "",
        text: `${this.localization("Are You Sure")}`,
        type: "warning",
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success ",
        cancelButtonClass: "btn btn-danger ",
        confirmButtonText: `${this.localization("Delete")}`,
        cancelButtonText: `${this.localization("Cancel")}`,
        buttonsStyling: false
      }).then(date => {
        if (date.isConfirmed) {
          this.$store
            .dispatch("user/delete_invited_member_from_team", {
              team_id: teamId,
              email: email
            })
            .then(_ => {
              this.get_all_teams();
              Swal.fire({
                title: "",
                icon: "success",
                type: "success",
                text: `${this.localization("Deleted")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false
              });
            });
        }
      });
    },
    search(query) {
      this.usersRoles(query);
    },
    usersRoles(search = "", limit = 10) {
      this.$store
        .dispatch("contacts/GetContactsList", {
          search_text: search
        })
        .then(() => {
          this.search_group = this.$store.getters["contacts/contactList"];
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.main_teams_wrapper {
  .main_timeline_wrapper {
    margin-bottom: 50px;

    .steps_container {
      display: flex;
      justify-content: space-between;
      position: relative;
      margin-bottom: 15px;
      @media (min-width: 320px) and (max-width: 1200px) {
        display: none;
      }
      .one_step_wrapper {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        position: relative;
        &::after {
          // background-color: #e3e3e3;
          background-color: #f7f7f7;
          position: absolute;
          content: "";
          left: 0;
          top: 50%;
          width: 100%;
          height: 4px;
        }
        &.first_step {
          &::after {
            background-color: #e5e5e5;
            background-color: #3c3;
            background-color: #11bfe3;
          }
          .step {
            background-color: #3c3;
            background-color: #11bfe3;
            color: #fff;
          }
        }
        .step {
          position: relative;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          // background: #f7f7f7;
          background-color: #f7f7f7;
          box-shadow: 0 5px 10px rgba(#000, 0.11);
          z-index: 2;
          color: #ccc;
          text-align: center;
          line-height: 30px;
          display: block;

          &::before {
            position: absolute;
            content: "";
            left: 50%;
            transform: translate(-50%, -50%);
            bottom: -30px;
            border-width: 15px;
            border-color: transparent transparent #f7f7f7 transparent;
            border-style: solid;
          }
        }
      }
    }
    .all_steps_cards_container {
      display: flex;
      justify-content: space-between;
      gap: 20px;

      .every_card_step {
        background-color: #fff;
        width: 25%;
        border-radius: 5px;
        box-shadow: 0 5px 10px rgba(#000, 0.11);
        transition: 0.3s;
        color: #777;
        transition: 0.5s;
        padding: 25px 10px 10px;
        height: 100%;
        min-height: 285px;

        display: block;
        cursor: pointer;
        h4 {
          margin: 0 0 15px;
          text-transform: capitalize;
          transition: 0.5s;
          font-size: 18px;
          cursor: pointer;
          text-align: center;
        }
        p {
          margin: 0;
          line-height: 1.7;
          cursor: pointer;
        }
        &:hover {
          h4 {
            color: #23ccef;
            cursor: pointer;
          }
        }
        // }
        // .router_cards {
        .router_cards {
          color: #777;
          transition: 0.5s;

          height: 100%;

          display: block;
          cursor: pointer;
          h4 {
            margin: 0 0 15px;
            text-transform: capitalize;
            transition: 0.5s;
            font-size: 18px;
            cursor: pointer;
            position: relative;
            &::first-letter {
              font-weight: bold;
              font-size: 20px;
            }
          }
          p {
            margin: 0;
            line-height: 1.7;
            cursor: pointer;
          }
          &:hover {
            h4 {
              color: #23ccef;
              cursor: pointer;
            }
          }
        }
        @media (min-width: 320px) and (max-width: 1200px) {
          min-width: 300px;

          margin-bottom: 10px;
        }
      }
      @media (min-width: 320px) and (max-width: 1200px) {
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }

  // Start All Teams
  .header_contact_wrapping {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @media (min-width: 320px) and (max-width: 480px) {
      display: block;
    }
  }
  .header_buttons_wrapper {
    display: flex;
    gap: 10px;
    @media (min-width: 320px) and (max-width: 480px) {
      margin-top: 10px;
    }
    .add_contact_buttons {
      @media (min-width: 320px) and (max-width: 400px) {
        min-width: 130px;
        padding: 8px;
        font-size: 15px;
      }
    }
  }
  .all_teams_container {
    margin-top: 2rem;
    .every_team {
      margin: 2rem 0;
      border-bottom: 1px solid #ccc;
      display: flex;
      justify-content: space-between;
      padding-bottom: 1rem;
      @media (min-width: 320px) and (max-width: 480px) {
        display: block;
      }
      .main_route {
        @media (min-width: 320px) and (max-width: 480px) {
          margin-bottom: 10px;
        }
        .team_name {
          color: #777;
          text-decoration: none;
          font-size: 1.2rem;
          text-transform: capitalize;
          transition: 0.3s;
          &.disabled {
            opacity: 0.5;
            pointer-events: none;
            cursor: no-drop;
          }
          &:hover {
            color: #11bfe3;
          }
        }
        .team_name_test {
          color: #777;
          text-decoration: none;
          font-size: 1.2rem;
          text-transform: capitalize;
          transition: 0.3s;

          cursor: pointer;
          &.disabled {
            opacity: 0.5;
          }
          &:hover {
            color: #11bfe3;
          }
        }
      }
      .main_actions {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        .actions-icons {
          font-size: 14px;
          width: 30px;
          height: 30px;
          box-shadow: 0 0 6px 0 rgba(#000, 0.2), 0 0 5px -2px rgba(#000, 0.1);
          text-align: center;
          line-height: 30px;
          border-radius: 50%;
          cursor: pointer;
          color: #777;
          transition: 0.3s;
          &.team_count {
            background-color: #11bfe3;
            color: #fff;
          }
          &.add {
            &:hover {
              background-color: #11bfe3;
              color: #fff;
            }
          }
          &.edit {
            &:hover {
              background-color: #ffa534;
              color: #fff;
            }
          }
          &.reinvite {
            &:hover {
              background-color: #3c3;
              color: #fff;
            }
          }
          &.delete {
            &:hover {
              background-color: #fb404b;
              color: #fff;
            }
          }
        }
      }
    }
  }
  // End All Teams
  .text_area_desc {
    height: 150px !important;
    line-height: 1.7 !important;
    padding: 10px 15px;
    resize: none;
    color: #606266;
    width: 100%;
    border: 1px solid #dcdfe6;
    outline: none;
    transition: 0.3s;
    border-radius: 4px;
    word-break: break-word;
    &:focus {
      border-color: #11bfe3;
    }
    &::placeholder {
      color: rgba(#606266, 0.5);
      font-size: 14px;
      font-weight: 400;
    }
  }
}

// Start Collapse
::v-deep .el-collapse-item__header {
  height: auto;
  cursor: pointer;
  line-height: 38px;
  .wrapper_collapse_for_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    @media (min-width: 320px) and (max-width: 480px) {
      display: block;
      padding: 10px 0;
    }
    // .head_part_one {
    // }
  }
  .head_part_two {
    .main_actions {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      .actions-icons {
        font-size: 14px;
        width: 30px;
        height: 30px;
        box-shadow: 0 0 6px 0 rgba(#000, 0.2), 0 0 5px -2px rgba(#000, 0.1);
        text-align: center;
        line-height: 30px;
        border-radius: 50%;
        cursor: pointer;
        color: #777;
        transition: 0.3s;
        &.team_count {
          background-color: #11bfe3;
          color: #fff;
        }
        &.add {
          &:hover {
            background-color: #11bfe3;
            color: #fff;
          }
        }

        &.edit {
          &:hover {
            background-color: #ffa534;
            color: #fff;
          }
        }
        &.reinvite {
          &:hover {
            background-color: #3c3;
            color: #fff;
          }
        }
        &.delete {
          &:hover {
            background-color: #fb404b;
            color: #fff;
          }
        }
      }
    }
  }
}
.team_description {
  padding-top: 1rem;
  font-size: 16px;
  color: #777;
}
.all_details_for_team_members {
  .every_team_members {
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;
    position: relative;
    @media (min-width: 320px) and (max-width: 480px) {
      display: block;
    }
    .part_a_member {
      min-width: 100px;
      @media (min-width: 320px) and (max-width: 480px) {
        margin-bottom: 15px;
      }
    }
    .part_b_member {
      flex-grow: 1;
      .main-scores-container {
        display: flex;
        justify-content: space-between;

        .every-score {
          gap: 7px;
          .score-word {
            color: #777;
          }
          .label {
            padding: 0.5rem;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            text-align: center;
            color: #fff;
            &.precision {
              background-color: #3c3;
            }

            &.technical_reasoning {
              background-color: #f93;
            }

            &.sequence {
              background-color: #39c;
            }

            &.confluence {
              background-color: #c36;
            }
          }
        }
      }
    }
    .part_c_member {
      .main_actions {
        display: flex;
        justify-content: center;
        min-width: 50px;
        gap: 10px;
        .actions-icons {
          font-size: 14px;
          width: 30px;
          height: 30px;
          box-shadow: 0 0 6px 0 rgba(#000, 0.2), 0 0 5px -2px rgba(#000, 0.1);
          text-align: center;
          line-height: 30px;
          border-radius: 50%;
          cursor: pointer;
          color: #777;
          transition: 0.3s;
          &.team_count {
            background-color: #11bfe3;
            color: #fff;
          }
          &.add {
            &:hover {
              background-color: #11bfe3;
              color: #fff;
            }
          }

          &.edit {
            &:hover {
              background-color: #ffa534;
              color: #fff;
            }
          }
          &.reinvite {
            &:hover {
              background-color: #3c3;
              color: #fff;
            }
          }
          &.delete {
            &:hover {
              background-color: #fb404b;
              color: #fff;
            }
          }
        }
      }
      @media (min-width: 320px) and (max-width: 480px) {
        position: absolute;
        top: 15px;
        right: 10px;
      }
    }
  }
}
.main_invitation_members_wrapper {
  .every_invitation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0.5rem 0 0.5rem;

    .part_i {
      display: flex;
      gap: 5px;
      align-items: center;
      color: #777;
      .email_address {
        opacity: 0.5;
      }
      .email_icon {
        color: #11bfe3;
      }
    }
    .part_ii {
      .actions-icons {
        font-size: 14px;
        width: 30px;
        height: 30px;
        box-shadow: 0 0 6px 0 rgba(#000, 0.2), 0 0 5px -2px rgba(#000, 0.1);
        text-align: center;
        line-height: 30px;
        border-radius: 50%;
        cursor: pointer;
        color: #777;
        transition: 0.3s;
        &.delete {
          &:hover {
            background-color: #fb404b;
            color: #fff;
          }
        }
      }
    }
  }
}
// // End Collapse
::v-deep .el-dialog {
  @media (min-width: 320px) and (max-width: 480px) {
    width: 90% !important;
  }
  @media (min-width: 480px) and (max-width: 767px) {
    width: 70% !important;
  }
}
.dialog_footer {
  display: flex;
  justify-content: end;
  align-items: center;
  @media (min-width: 320px) and (max-width: 991px) {
    justify-content: center;
  }
}
</style>
